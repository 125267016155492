import api from '@/api/banks.api'
import Bank from '@/models/Bank'
import Currency from '@/models/Currency'

export default {
  namespaced: true,

  state: {
    perpage: 10,
    defaultBankSettingsStructure: {},
  },

  actions: {
    /**
     * Загрузить банки.
     * @return {Promise<T | never>}
     */
    loadBanks: () =>
      api
        .getBanks()
        .then((banks) =>
          Bank.insertOrUpdate({ data: Array.isArray(banks) ? banks : [] })
        ),

    /**
     * Создать банк.
     * @param context
     * @param {object} bankData
     * @return {*|Promise<T|never>}
     */
    postBank: (context, bankData) => api.postBank(bankData),

    /**
     * Редактировать банк.
     * @param context
     * @param {object} bankData
     * @return {*|Promise<T|never>}
     */
    putBank: (context, bankData) => api.putBank(bankData),

    /**
     * Удалить банк.
     * @param context
     * @param {int} bankId
     * @return {*|Promise<T|never>}
     */
    deleteBank: (context, bankId) => api.deleteBank(bankId),

    /**
     * Восстановить банк.
     * @param context
     * @param {int} bankId
     * @return {*|Promise<T|never>|Promise<void|never>}
     */
    restoreBank: (context, bankId) => api.restoreUser(bankId),

    /**
     * Загрузить валюты.
     * @return {Promise<T | never>}
     */
    loadCurrency: () =>
      api
        .getCurrency()
        .then((currency) => Currency.insertOrUpdate({ data: currency })),

    loadDefaultSettings: ({ state }) =>
      api
        .getDefaultSettings()
        .then((settings) => (state.defaultBankSettingsStructure = settings)),
  },

  getters: {
    getPerpage: (state) => state.perpage,

    getDefaultSettings: (state) => state.defaultBankSettingsStructure,
  },
}
