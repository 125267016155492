import { Snackbar } from 'buefy/dist/components/snackbar/index'
import AuthUser from '@/models/AuthUser'
import validationErrors from '@/mixins/validationErrors'
import _ from 'lodash'

const axiosConfig = {
  /**
   * Default Base URL
   */
  baseURL: process.env.VUE_APP_API_URL || '',

  /**
   * Default URL
   */
  url: '/',

  /**
   * Default Method
   */
  method: 'get',

  access_token: () => localStorage.getItem('token'),

  /**
   * Default Headers
   */
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },

  /**
   * Default Data
   */
  data: {},

  /**
   * Default Timout
   */
  timeout: 60 * 1000,

  /**
   * Default With Credentials Flag
   */
  withCredentials: false,

  /**
   * Default Response Type
   */
  responseType: 'json',

  /**
   * Default Response Encoding
   */
  responseEncoding: 'utf8',

  /**
   * Default Validate Status Method
   * @param {number} status
   */
  validateStatus(status) {
    return status >= 200 && status < 300 // default
  },

  /**
   * Default Max Redirects
   */
  maxRedirects: 5,

  /**
   * Default Socket Path
   */
  socketPath: null,

  /**
   * Default Proxy
   */
  proxy: {},

  /**
   * Default on Response
   * @param {object} response
   */
  onResponse(response) {
    return response.data
  },

  async onRequest(config) {
    if (localStorage.getItem('token')) {
      config.headers['Authorization'] =
        'Bearer ' + localStorage.getItem('token')
    }
    if (!['auth/login', 'auth/refresh', 'auth/logout'].includes(config.url)) {
      AuthUser.dispatch('checkExpiresIn')
    }
    return config
  },

  /**
   * On 401 Unauthorised
   * @param {object} error
   */
  // eslint-disable-next-line no-unused-vars
  onUnauthorised(error) {
    Snackbar.open({ message: 'Access denied.', type: 'is-danger' })
  },

  /**
   * On 404 Not Found
   * @param {object} error
   */
  // eslint-disable-next-line no-unused-vars
  onNotFound(error) {
    Snackbar.open({ message: 'Resource not found.', type: 'is-danger' })
  },

  /**
   * On 500 Server Error
   * @param {object} error
   */
  onServerError(error) {
    Snackbar.open({
      message: error.response.data,
      type: 'is-danger',
    })
  },

  /**
   * On Generic Error
   * @param {Error} error
   */
  onGenericError(error) {
    Snackbar.open({ message: error.message, type: 'is-danger' })
    console.error(error)
  },

  /**
   * On Laravel Validation Error (Or 422 Error).
   * @param {object} error
   */
  onValidationError(error) {
    console.log('generic', error)
    if (_.isArray(error.response.data))
      validationErrors.methods.showValidationErrors(error.response.data)
    else
      Snackbar.open({
        message: error.response.data,
        type: 'is-danger',
      })
  },

  /**
   * On 429 Too Many Requests
   * @param error
   */
  // eslint-disable-next-line no-unused-vars
  onTooManyRequests(error) {
    Snackbar.open({
      message: 'Too many requests. Try again later.',
      type: 'is-danger',
    })
  },

  /**
   * Default on Error
   * @param {object} error
   */
  onError(error) {
    const { response } = error
    const errorTypes = {
      401: this.onUnauthorised,
      404: this.onNotFound,
      422: this.onValidationError,
      429: this.onTooManyRequests,
      500: this.onServerError,
    }
    response && response.status in errorTypes
      ? errorTypes[response.status](error)
      : this.onGenericError(error)

    return Promise.reject(error)
  },
}

export default axiosConfig
