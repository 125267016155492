<template>
  <section>
    <b-modal :active="true" has-modal-card @close="closeModal">
      <form action="">
        <div class="modal-card is-full">
          <header class="modal-card-head">
            <p class="modal-card-title">
              <b-icon class="has-text-danger" icon="alert-circle"></b-icon>
              <span>{{ isDeleting ? 'Delete' : 'Restore' }} user</span>
            </p>
          </header>
          <section class="modal-card-body">
            <p>
              Username: <strong>{{ username }}</strong>
            </p>
            <p>
              Email: <strong>{{ email }}</strong>
            </p>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Close
            </button>
            <button
              class="button is-danger"
              type="submit"
              @click.prevent="postRequest"
            >
              {{ isDeleting ? 'Delete' : 'Restore' }}
            </button>
          </footer>
        </div>
      </form>
    </b-modal>
  </section>
</template>

<script>
import User from '@/models/User'

export default {
  name: 'DeleteUser',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isDeleting: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    username: ({ data }) => data.row.username || '',
    email: ({ data }) => data.row.email || '',
  },

  created() {
    if (!this.data.row) {
      this.$router.back()
    }
  },

  methods: {
    postRequest() {
      this.isDeleting
        ? User.dispatch('deleteUser', this.data.row.id).then((message) => {
            this.$snackbar.open(message)
            this.$emit('user-deleted')
            this.closeModal()
          })
        : User.dispatch('restoreUser', this.data.row.id).then((message) => {
            this.$snackbar.open(message)
            this.$emit('user-deleted')
            this.closeModal()
          })
    },

    closeModal() {
      this.$router.back()
    },
  },
}
</script>
