import api from '@/helpers/api'

export default {
  /**
   * Получить всех доступных пользователей
   * @returns {*}
   */
  getUsers: () => api.sendRequest('get', 'user'),

  /**
   * Удалить пользователя
   * @param {integer|string} userId
   * @returns {*}
   */
  deleteUser: (userId) => api.sendRequest('delete', `user/${userId}`),

  /**
   * Восстановить пользователя
   * @param {integer|string} userId
   * @returns {*}
   */
  restoreUser: (userId) => api.sendRequest('patch', `user/${userId}`),

  /**
   * Создать пользователя
   * @param {object} userData
   * @returns {*}
   */
  postUser: (userData) => api.sendRequest('post', 'user', userData),

  /**
   * Редактировать пользователя
   * @param {object} userData
   * @returns {*}
   */
  putUser: (userData) =>
    api.sendRequest('put', `user/${userData.userId}`, userData),

  /**
   * Получить роли пользователя
   * @param {string|integer} userId
   * @returns {*}
   */
  getUserRole: (userId) => api.sendRequest('get', `user/${userId}/roles`),
}
