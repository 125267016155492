import { Model } from '@vuex-orm/core'
import Partner from '@/models/Partner'
import Currency from '@/models/Currency'
import dateParser from '@/helpers/dateParser'
import _ from 'lodash'

export default class Bank extends Model {
  static entity = 'banks'

  static fields() {
    return {
      id: this.increment(),
      description: this.attr(''),
      isActive: this.boolean(false),
      currencySettings: this.attr(undefined),
      limitSettings: this.attr(undefined),
      deletedAt: this.attr('', (date) => dateParser.beautifyDate(date)),
      createdAt: this.attr('', (date) => dateParser.beautifyDate(date)),
      updatedAt: this.attr('', (date) => dateParser.beautifyDate(date)),
      bankPartnerUid: this.attr(''),
      currencyId: this.number(0),
      partner: this.belongsTo(Partner, 'bankPartnerUid', 'partnerUid'),
      currency: this.belongsTo(Currency, 'currencyId'),
    }
  }

  get currencyTitle() {
    return this.currency ? this.currency.title : null
  }

  get defaultSettings() {
    if (
      this.currencySettings === undefined &&
      this.limitSettings === undefined
    ) {
      let settings = this.$store().getters['entities/banks/getDefaultSettings']
      settings = _(settings)
        .mapValues((section) =>
          _(section).keyBy('title').mapValues('none').value()
        )
        .value()
      return settings
    } else {
      return {
        CurrencySettings: this.currencySettings,
        LimitSettings: this.limitSettings,
      }
    }
  }

  /*static mutators() {
    return {
      currencySettings: value => {
        if (value === '') {
          return _(
            this.store().getters['entities/banks/getDefaultSettings']
              .CurrencySettings
          )
            .keyBy('title')
            .mapValues('input')
            .value()
        }
        return value
      },
      limitSettings: value => {
        if (value === '') {
          return _(
            this.store().getters['entities/banks/getDefaultSettings']
              .LimitSettings
          )
            .keyBy('title')
            .mapValues('input')
            .value()
        }
        return value
      },
    }
  }*/
}
