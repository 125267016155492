import { Model } from '@vuex-orm/core'
import Role from '@/models/Role'
import User from '@/models/User'

export default class RoleUser extends Model {
  static entity = 'roleUsers'

  static primaryKey = ['userId', 'roleId']

  static fields() {
    return {
      roleId: this.number(0),
      userId: this.number(0),
      has: this.boolean(false),
      delegate: this.boolean(false),
      role: this.belongsTo(Role, 'id', 'roleId'),
      user: this.belongsTo(User, 'id', 'userId'),
    }
  }
}
