import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import Vue from 'vue'
import User from '@/models/User'
import users from '@/store/modules/users'
import Role from '@/models/Role'
import RoleUser from '@/models/RoleUser'
import AuthUser from '@/models/AuthUser'
import auth from '@/store/modules/auth'
import Partner from '@/models/Partner'
import partners from '@/store/modules/partners'
import RoleGroup from '@/models/RoleGroup'
import GameType from '@/models/GameType'
import Game from '@/models/Game'
import games from '@/store/modules/games'
import GamePartner from '@/models/GamePartner'
import Bank from '@/models/Bank'
import banks from '@/store/modules/banks'
import Currency from '@/models/Currency'

Vue.use(Vuex)

const database = new VuexORM.Database()

database.register(User, users)
database.register(Role)
database.register(RoleUser)
database.register(AuthUser, auth)
database.register(Partner, partners)
database.register(RoleGroup)
database.register(GameType)
database.register(Game, games)
database.register(GamePartner)
database.register(Bank, banks)
database.register(Currency)

export default database
