<template>
  <b-field
    :message="errors.first(title)"
    :type="{ 'is-danger': errors.has(title) }"
  >
    <p class="control">
      <span class="button is-static">{{ title }}</span>
    </p>
    <b-checkbox
      v-model="selectedValue"
      :name="title"
      :disabled="!editable"
    ></b-checkbox>
  </b-field>
</template>

<script>
export default {
  name: 'BooleanParameter',

  inject: ['$validator'],

  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    stored: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
    },
  },

  computed: {
    selectedValue: {
      get() {
        if (this.stored === null) this.$emit('update:stored', false)

        return this.stored
      },
      set(value) {
        this.$emit('update:stored', value)
      },
    },
  },
}
</script>
