<template>
  <b-field
    :message="errors.first(title)"
    :type="{ 'is-danger': errors.has(title) }"
  >
    <p class="control">
      <span class="button is-static">{{ title }}</span>
    </p>
    <b-taginput
      v-model="selectedValue"
      :name="title"
      :disabled="!editable"
      :closable="editable"
      @input="sortArrayAndParseInt"
    ></b-taginput>
  </b-field>
</template>

<script>
export default {
  name: 'OptionalPositiveIntegerArrayParameter',

  inject: ['$validator'],

  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    stored: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
    },
  },

  data() {
    return {
      selectedValue: this.stored,
    }
  },

  watch: {
    selectedValue() {
      this.$emit('update:stored', this.selectedValue)
    },
  },

  methods: {
    sortArrayAndParseInt() {
      this.selectedValue = this.$_(this.selectedValue)
        .map((val) => this.$_.parseInt(val))
        .remove((n) => n > 0)
        .sort((a, b) => a - b)
        .sortedUniq()
        .value()
    },
  },
}
</script>
