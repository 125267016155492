<template>
  <section>
    <b-modal :active="true" has-modal-card @close="closeModal">
      <form @submit.prevent="saveGame">
        <div class="modal-card is-full">
          <header class="modal-card-head">
            <p class="modal-card-title">
              {{ $route.params.new ? 'Create' : 'Edit' }} game
            </p>
          </header>
          <section class="modal-card-body">
            <b-field
              label="Game ID"
              :message="errors.first('gameId')"
              :type="{ 'is-danger': errors.has('gameId') }"
            >
              <b-input
                ref="gameId"
                v-model="gameId"
                v-validate="{ required: true, integer: true }"
                name="gameId"
                placeholder="Type hardcoded game ID from GBE"
                type="number"
                :disabled="!$route.params.new"
              ></b-input>
            </b-field>
            <b-field
              label="Title"
              :message="errors.first('title')"
              :type="{ 'is-danger': errors.has('title') }"
            >
              <b-input
                ref="title"
                v-model="title"
                v-validate="{
                  required: true,
                }"
                name="title"
                placeholder="Type game title"
                type="text"
              ></b-input>
            </b-field>
            <b-field label="Picture link">
              <b-input
                v-model="pictureLink"
                type="text"
                placeholder="Input link to default image"
              ></b-input>
            </b-field>
            <b-field label="Description">
              <b-input
                v-model="description"
                placeholder="Type some description"
                type="textarea"
              ></b-input>
            </b-field>
            <settings-form
              :settings="defaultSettings"
              :selected-settings.sync="gameDefaultSettings"
              :appendable-sections="appendableSections"
              :is-editing="isEditing"
              :set-settings-sections="setSettingsSections"
            ></settings-form>
            <b-field grouped>
              <b-field label="Background color">
                <color-picker v-model="colors"></color-picker>
              </b-field>
              <b-field label="Active">
                <b-checkbox v-model="isActive"></b-checkbox>
              </b-field>
              <b-field
                label="Type"
                :message="errors.first('type')"
                :type="{ 'is-danger': errors.has('type') }"
              >
                <b-select
                  ref="type"
                  v-model="type"
                  v-validate="{ required: true }"
                  name="type"
                  :loading="loading"
                >
                  <option
                    v-for="gameType in gameTypes"
                    :key="gameType.id"
                    :value="gameType.id"
                  >
                    {{ gameType.title }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Close
            </button>
            <button class="button is-primary" type="submit">
              {{ $route.params.new ? 'Create' : 'Save' }}
            </button>
          </footer>
        </div>
      </form>
    </b-modal>
  </section>
</template>

<script>
import SettingsForm from '@/components/Settings/SettingsForm/SettingsForm'
import Game from '@/models/Game'
import GameType from '@/models/GameType'
import ColorPicker from 'vue-color/src/components/Material'
import { mapState } from 'vuex'

export default {
  name: 'GameForm',

  components: {
    'settings-form': SettingsForm,
    'color-picker': ColorPicker,
  },

  $_veeValidate: {
    validator: 'new',
  },

  provide() {
    return {
      $validator: this.$validator,
    }
  },

  props: {
    game: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isEditing: !this.$route.params.new,
      gameId: this.game.id,
      title: this.game.title,
      type: this.game.gameTypeId,
      description: this.game.description,
      isActive: this.game.isActive,
      pictureLink: this.game.pictureLink,
      colors: this.game.colors,
      gameDefaultSettings: this.game.defaultSettings,
      appendableSections: [],
      isLoading: false,
      settingsToSave: {},
    }
  },

  computed: {
    ...mapState(['loading']),
    gameTypes: () => GameType.all(),
    defaultSettings() {
      let settings = Game.getters('getDefaultSettings')
      // eslint-disable-next-line
      this.gameDefaultSettings = Object.assign({}, this.game.defaultSettings)
      // eslint-disable-next-line
      this.appendableSections = []
      for (let key in settings) {
        let keyInDefaultSettings = key in this.gameDefaultSettings
        // eslint-disable-next-line
        this.appendableSections[key] = this.isEditing
          ? !keyInDefaultSettings
          : true
        if (!keyInDefaultSettings) {
          // eslint-disable-next-line
          this.gameDefaultSettings[key] = this.recreateSettingsFromArray(
            settings[key]
          )
        }
      }
      return settings
    },
  },

  created() {
    Game.dispatch('loadGameTypes')
    this.settingsToSave = Object.assign({}, this.game.defaultSettings)
  },

  methods: {
    setSettingsSections(settings) {
      this.settingsToSave = settings
    },
    recreateSettingsFromArray(array) {
      let obj = {}
      for (let key in array) {
        obj[array[key]['title']] = array[key]['value']
      }
      return obj
    },
    saveGame() {
      this.$validator
        .validateAll()
        .then((valid) => (valid ? Promise.resolve() : Promise.reject()))
        .then(
          () => {
            let gameData = {
              game_id: this.gameId,
              title: this.title,
              description: this.description,
              is_active: this.isActive,
              game_type_id: this.type,
              picture_link: this.pictureLink,
              color: {
                hsl: this.colors.hsl,
                hex: this.colors.hex,
                rgba: this.colors.rgba,
                hsv: this.colors.hsv,
              },
              default_settings: this.settingsToSave,
            }
            this.isEditing
              ? Game.dispatch('putGame', gameData).then((message) => {
                  this.$emit('game-edited')
                  this.closeModal()
                  this.$snackbar.open(message)
                })
              : Game.dispatch('postGame', gameData).then((message) => {
                  this.$emit('game-created')
                  this.closeModal()
                  this.$snackbar.open(message)
                })
          },
          () => {
            this.$snackbar.open({
              message: "Form isn't valid. Please check the fields.",
              type: 'is-danger',
            })
          }
        )
    },
    closeModal() {
      if (!this.isEditing) {
        Game.delete(this.gameId)
      }
      this.$router.back()
    },
  },

  async beforeRouteEnter(to, from, next) {
    if (to.params.new) {
      await Game.new().then((game) => (to.params.game = game))
    }
    next()
  },
}
</script>
