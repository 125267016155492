<template>
  <section>
    <b-modal :active="true" has-modal-card @close="closeModal">
      <form class="modal-card is-full" @submit.prevent="saveSettings">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit game settings</p>
        </header>
        <section class="modal-card-body">
          <settings-form
            :settings="defaultSettings"
            :selected-settings="partnerGameSettings"
          ></settings-form>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal">
            Close
          </button>
          <button class="button is-primary" type="submit">Save</button>
        </footer>
      </form>
    </b-modal>
  </section>
</template>

<script>
import SettingsForm from '@/components/Settings/SettingsForm/SettingsForm'
import AuthUser from '@/models/AuthUser'
import Game from '@/models/Game'
import GamePartner from '@/models/GamePartner'

export default {
  name: 'PartnerGameForm',

  components: { 'settings-form': SettingsForm },

  props: {
    game: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    defaultSettings() {
      return this.$_.pickBy(Game.getters('getDefaultSettings'), (value, key) =>
        this.$_.has(this.game.defaultSettings, key)
      )
    },
    partnerGameSettings() {
      return GamePartner.query()
        .where('gamePartnerUid', AuthUser.query().first().partnerUid)
        .where('gameId', this.game.id)
        .first().gameSettings
    },
  },

  methods: {
    closeModal() {
      this.$router.back()
    },
    saveSettings() {
      let gameData = {
        gameId: this.game.id,
        partnerUid: AuthUser.query().first().partnerUid,
        gameSettings: this.partnerGameSettings,
      }
      Game.dispatch('saveGameSettings', gameData).then((m) => {
        this.$emit('game-edited')
        this.$snackbar.open(m)
        this.closeModal()
      })
    },
  },
}
</script>
