import { Model } from '@vuex-orm/core'
import Role from '@/models/Role'

export default class RoleGroup extends Model {
  static entity = 'roleGroup'

  static fields() {
    return {
      id: this.number(0),
      title: this.attr(null),
      roles: this.hasMany(Role, 'groupId'),
    }
  }
}
