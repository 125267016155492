import store from '@/store/store'
import authUser from '@/models/AuthUser'

export default {
  data: () => ({
    permission: {
      administrator: 'USERS_ADMINISTRATOR',
      superuser: 'USERS_SUPERUSER',
      usersAccess: 'USERS_ACCESS',
      usersCreate: 'USERS_CREATE',
      usersEdit: 'USERS_EDIT',
      usersDelete: 'USERS_DELETE',
      usersRestore: 'USERS_RESTORE',
      partnersAccess: 'PARTNERS_ACCESS',
      partnersCreate: 'PARTNERS_CREATE',
      partnersEdit: 'PARTNERS_EDIT',
      partnersDelete: 'PARTNERS_DELETE',
      gamesAccess: 'GAMES_ACCESS',
      gamesEdit: 'GAMES_EDIT',
      gamesCreate: 'GAMES_CREATE',
      gamesDelete: 'GAMES_DELETE',
      banksAccess: 'BANKS_ACCESS',
      banksEdit: 'BANKS_EDIT',
      banksCreate: 'BANKS_CREATE',
      banksDelete: 'BANKS_DELETE',
      reportsAccess: 'REPORTS_ACCESS',
    },
  }),
  methods: {
    /**
     * Проверить пермишен у пользователя
     *
     * при передаче массива пермишенов результат истинен,
     * если у пользователя есть хотя бы один пермишен
     *
     * @param {string|Array} permission
     * @return {boolean}
     */
    checkPermission: (permission) =>
      !permission
        ? true
        : Array.isArray(permission)
        ? !!permission.some((p) =>
            store.getters[authUser.namespace('getUserPermissions')].includes(p)
          )
        : !!store.getters[authUser.namespace('getUserPermissions')].includes(
            permission
          ),
  },
}
