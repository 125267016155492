<style lang="scss" scoped>
.spa-header-logo {
  background-image: url('../assets/logo.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 130px;
}
</style>

<template>
  <section v-if="headerVisibility">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <span class="navbar-item spa-header-logo"></span>

        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="spaHeaderMenu"
          :class="{ 'is-active': showMenu }"
          @click="showMenu = !showMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        id="spaHeaderMenu"
        class="navbar-menu"
        :class="{ 'is-active': showMenu }"
      >
        <div class="navbar-start">
          <router-link class="navbar-item" to="home"> Home </router-link>
          <router-link
            v-if="checkPermission(permission.usersAccess)"
            class="navbar-item"
            to="users"
          >
            Users
          </router-link>
          <router-link
            v-if="
              checkPermission(permission.gamesAccess) &&
              !checkPermission([permission.administrator, permission.superuser])
            "
            class="navbar-item"
            to="games"
          >
            Games
          </router-link>
          <router-link
            v-if="checkPermission(permission.banksAccess)"
            class="navbar-item"
            to="banks"
          >
            Banks
          </router-link>
          <router-link
            v-if="checkPermission(permission.partnersAccess)"
            class="navbar-item"
            to="partners"
          >
            Partners
          </router-link>

          <router-link
            v-if="
              checkPermission([permission.administrator, permission.superuser])
            "
            class="navbar-item"
            to="allgames"
          >
            All games
          </router-link>
          <router-link
            v-if="checkPermission(permission.reportsAccess)"
            to="reports"
            class="navbar-item"
          >
            Reports
          </router-link>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <a class="button is-inverted" @click="logout">Logout</a>
          </div>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
import AuthUser from '@/models/AuthUser'
import { mapState } from 'vuex'

export default {
  name: 'Header',

  data() {
    return {
      showMenu: false,
    }
  },

  computed: {
    ...mapState(['headerVisibility']),
  },

  methods: {
    /**
     * Разлогиниться
     * @returns {Promise<void>}
     */
    async logout() {
      let message = await AuthUser.dispatch('logout')
      this.$snackbar.open(message)
      this.$router.push('/login')
    },
  },
}
</script>
