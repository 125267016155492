import api from '@/helpers/api'

export default {
  /**
   * Получить список игр.
   * @return {Promise<T | never>}
   */
  getGames: () => api.sendRequest('get', 'game'),

  /**
   * Получить список игр для отчетов.
   * @return {Promise<T | never>}
   */
  getGamesForReports: () => api.sendRequest('get', 'game/reports'),

  /**
   * Создать игру.
   * @param {Object} gameData
   * @return {Promise<T | never>}
   */
  postGame: (gameData) => api.sendRequest('post', 'game', gameData),

  /**
   * Редактировать игру.
   * @param {Object} gameData
   * @return {Promise<T | never>}
   */
  putGame: (gameData) =>
    api.sendRequest('put', `game/${gameData.game_id}`, gameData),

  /**
   * Удалить игру.
   * @param {int} gameId
   * @return {Promise<T | never>}
   */
  deleteGame: (gameId) => api.sendRequest('delete', `game/${gameId}`),

  /**
   * Получить список типов игр.
   * @return {Promise<T | never>}
   */
  getGameTypes: () => api.sendRequest('get', 'game/types'),

  postGameSettings: (gameData) =>
    api.sendRequest(
      'post',
      `partner/${gameData.partnerUid}/game/${gameData.gameId}/settings`,
      gameData
    ),

  postMassiveGameSettings: (gameData) =>
    api.sendRequest(
      'post',
      `partner/${gameData.partnerUid}/game/${gameData.gameId}/massive-settings`,
      gameData
    ),

  getDefaultSettings: () => api.sendRequest('get', 'game/settings'),

  getMassiveSettings: () => api.sendRequest('get', 'game/massive-settings'),
}
