<template>
  <div id="app">
    <spa-header></spa-header>
    <router-view />
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/app';

#app {
  min-height: 100vh;
  background-color: $body-background-color;
}
</style>

<script>
import AuthUser from './models/AuthUser'
import Header from './components/Header.vue'

export default {
  name: 'App',

  components: {
    'spa-header': Header,
  },

  created() {
    let token = localStorage.getItem('token')
    if (token) {
      this.$store.dispatch(AuthUser.namespace('setTokenData'), token)
      let user = localStorage.getItem('user')
      if (!user) {
        this.$store.dispatch(AuthUser.namespace('getMe'))
      } else {
        AuthUser.insertOrUpdate({ data: JSON.parse(user) })
      }
    }
  },
}
</script>
