<template>
  <section>
    <b-modal :active="true" has-modal-card @close="$router.back()">
      <div class="modal-card is-full">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit partner's games</p>
        </header>
        <section class="modal-card-body is-paddingless">
          <b-table
            ref="gamesTable"
            :data="games"
            :loading="loading"
            detail-key="id"
            detailed
            narrowed
            checkable
            checkbox-position="right"
            :checked-rows.sync="checkedRows"
          >
            <template slot-scope="props">
              <b-table-column
                field="id"
                label="Game ID"
                numeric
                width="80"
                sortable
              >
                {{ props.row.id }}
              </b-table-column>
              <b-table-column field="title" label="Title" sortable>
                {{ props.row.title }}
              </b-table-column>
            </template>

            <template slot="detail" slot-scope="props">
              <settings-form
                :settings="defaultGameSettings(props.row)"
                :selected-settings="partnerGameSettings(props.row)"
                :editable="checkPermission(permission.administrator)"
              ></settings-form>
            </template>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large"></b-icon>
                  </p>
                  <p>Nothing here.</p>
                </div>
              </section>
            </template>
          </b-table>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="$router.back()">
            Close
          </button>
          <button
            class="button is-primary"
            type="submit"
            @click.prevent="saveGames"
          >
            Save
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import SettingsForm from '@/components/Settings/SettingsForm/SettingsForm'
import { mapState } from 'vuex'
import Game from '@/models/Game'
import Partner from '@/models/Partner'
import GamePartner from '@/models/GamePartner'

export default {
  name: 'PartnerGames',
  components: { SettingsForm },
  props: {
    partnerUid: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState(['loading']),
    /**
     * Все игры
     * @returns {Collection<InstanceOf<Game>>}
     */
    games: () => Game.query().with('gamePartners').get(),
    assignedGames() {
      return Game.query()
        .with('gamePartners')
        .whereHas('gamePartners', (query) =>
          query.where('gamePartnerUid', this.partnerUid).where('assigned', true)
        )
        .get()
    },
    checkedRows: {
      get() {
        return this.$_.intersectionBy(
          this.games,
          this.assignedGames,
          (game) => game.id
        )
      },
      set(checkedGames) {
        this.$_.differenceBy(
          this.assignedGames,
          checkedGames,
          (game) => game.id
        ).forEach((game) =>
          GamePartner.insertOrUpdate({
            data: {
              gameId: game.id,
              gamePartnerUid: this.partnerUid,
              assigned: false,
            },
          })
        )
        checkedGames.forEach((game) =>
          GamePartner.insertOrUpdate({
            data: {
              gameId: game.id,
              gamePartnerUid: this.partnerUid,
              assigned: true,
            },
          })
        )
      },
    },
  },

  methods: {
    /**
     * Сохранить игры партнёра.
     * @return void
     */
    saveGames() {
      let gamePartners = GamePartner.query()
        .where('gamePartnerUid', this.partnerUid)
        .get()
      Partner.dispatch('putPartnerGames', {
        partnerUid: this.partnerUid,
        data: { games: gamePartners },
      }).then((message) => {
        this.$snackbar.open(message)
        this.$emit('partner-edited')
        this.$router.back()
      })
    },
    defaultGameSettings(game) {
      return this.$_.pickBy(Game.getters('getDefaultSettings'), (value, key) =>
        this.$_.has(game.defaultSettings, key)
      )
    },
    partnerGameSettings(game) {
      let partnerGameSettings = GamePartner.query()
        .where('gamePartnerUid', this.partnerUid)
        .where('gameId', game.id)
        .first().gameSettings
      if (partnerGameSettings) {
        return partnerGameSettings
      } else {
        return game.defaultSettings
      }
    },
  },
  /**
   * Задаём неактивными все игры партнёру, чтобы было проще
   * @param to
   * @param from
   * @param next
   * @returns {Promise<void>}
   */
  beforeRouteEnter: async (to, from, next) => {
    let partnerUid = to.params.partnerUid
    await Game.dispatch('loadGames')
    await Game.all().forEach((game) =>
      GamePartner.insertOrUpdate({
        data: GamePartner.hydrate({
          gameId: game.id,
          gamePartnerUid: partnerUid,
          gameSettings: game.defaultSettings,
        }),
      })
    )
    Partner.dispatch('loadPartnerGames', partnerUid)
    Game.dispatch('loadDefaultSettings')
    next()
  },

  beforeRouteLeave: (to, from, next) =>
    Promise.all([GamePartner.deleteAll(), Game.deleteAll()]).then(() => next()),
}
</script>
