<template>
  <section>
    <b-collapse :open="false" class="card">
      <div slot="trigger" slot-scope="props" class="card-header" role="button">
        <p class="card-header-title">
          {{ rolesGroup.title }}
        </p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
        </a>
      </div>
      <div class="card-content is-paddingless">
        <b-table :data="roles" narrowed>
          <template slot-scope="props">
            <b-table-column class="is-capitalized" field="title" label="Title">
              {{ props.row.title }}
            </b-table-column>
            <b-table-column field="has" label="Has" width="100">
              <b-switch
                :value="userRoleHas(props.row.id)"
                @input="
                  (value) =>
                    assignRole({
                      userId: user.id,
                      roleId: props.row.id,
                      has: value,
                    })
                "
              ></b-switch>
            </b-table-column>
            <b-table-column field="delegate" label="Delegate" width="100">
              <b-switch
                :value="userRoleDelegate(props.row.id)"
                @input="
                  (value) =>
                    assignRole({
                      userId: user.id,
                      roleId: props.row.id,
                      delegate: value,
                    })
                "
              ></b-switch>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </b-collapse>
  </section>
</template>

<script>
import AuthUser from '@/models/AuthUser'
import RoleUser from '@/models/RoleUser'
import RoleGroup from '@/models/RoleGroup'

export default {
  name: 'RolePickerGroup',

  props: {
    title: {
      type: String,
      default: 'Title',
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    rolesGroup: {
      type: Object,
      default: () => RoleGroup.hydrate(),
    },
    roles: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    authUser: () => AuthUser.query().first(),
  },

  methods: {
    userRoleHas(roleId) {
      let roleUser = RoleUser.find(this.user.id + '_' + roleId)
      return roleUser ? roleUser.has : false
    },
    userRoleDelegate(roleId) {
      let roleUser = RoleUser.find(this.user.id + '_' + roleId)
      return roleUser ? roleUser.delegate : false
    },
    assignRole: (data) => RoleUser.insertOrUpdate({ data: data }),
  },
}
</script>
