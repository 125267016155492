import { Model } from '@vuex-orm/core'
import GameType from '@/models/GameType'
import Partner from '@/models/Partner'
import GamePartner from '@/models/GamePartner'
import colorPickerDefaults from '@/configs/colorPickerDefaults'
import _ from 'lodash'

export default class Game extends Model {
  static entity = 'games'

  static fields() {
    return {
      id: this.increment(),
      title: this.attr(''),
      isActive: this.boolean(false),
      description: this.attr(''),
      pictureLink: this.attr(''),
      color: this.attr(colorPickerDefaults),
      gameTypeId: this.attr(undefined),
      gameType: this.belongsTo(GameType, 'gameTypeId'),
      gamePartners: this.hasMany(GamePartner, 'gameId'),
      defaultSettings: this.attr(undefined, (value) => {
        if (value === undefined) {
          let settings =
            this.store().getters['entities/games/getDefaultSettings']
          settings = _(settings)
            .mapValues((section) =>
              _(section).keyBy('title').mapValues('none').value()
            )
            .value()
          return settings
        } else {
          return value
        }
      }),
      partners: this.belongsToMany(
        Partner,
        GamePartner,
        'gameId',
        'gamePartnerUid'
      ),
    }
  }

  get colors() {
    try {
      if (typeof this.color === 'string') {
        return JSON.parse(this.color) || colorPickerDefaults
      }
      return colorPickerDefaults
    } catch (e) {
      return colorPickerDefaults
    }
  }

  get gameTypeTitle() {
    return this.gameType ? this.gameType.title : null
  }
}
