const buefyConfig = {
  /*default*/ defaultContainerElement: null,
  /*default*/ defaultIconPack: 'mdi',
  /*default*/ defaultIconComponent: null,
  /*default*/ defaultDialogConfirmText: null,
  /*default*/ defaultDialogCancelText: null,
  /*default*/ defaultSnackbarDuration: 3500,
  /*default*/ defaultSnackbarPosition: null,
  /*default*/ defaultToastDuration: 2000,
  /*default*/ defaultToastPosition: null,
  /*default*/ defaultNotificationDuration: 2000,
  /*default*/ defaultNotificationPosition: null,
  /*default*/ defaultTooltipType: 'is-primary',
  defaultTooltipAnimated: true,
  /*default*/ defaultTooltipDelay: 0,
  /*default*/ defaultInputAutocomplete: 'on',
  /*default*/ defaultDateFormatter: null,
  /*default*/ defaultDateParser: null,
  /*default*/ defaultDateCreator: null,
  /*default*/ defaultDayNames: null,
  /*default*/ defaultMonthNames: null,
  /*default*/ defaultFirstDayOfWeek: null,
  /*default*/ defaultUnselectableDaysOfWeek: null,
  /*default*/ defaultTimeFormatter: null,
  /*default*/ defaultTimeParser: null,
  /*default*/ defaultModalCanCancel: ['escape', 'x', 'outside', 'button'],
  /*default*/ defaultModalScroll: null,
  /*default*/ defaultDatepickerMobileNative: true,
  /*default*/ defaultTimepickerMobileNative: true,
  defaultNoticeQueue: false,
  /*default*/ defaultInputHasCounter: true,
  /*default*/ defaultUseHtml5Validation: true,
  /*default*/ defaultDropdownMobileModal: true,
  /*default*/ defaultFieldLabelPosition: null,
  /*default*/ defaultDatepickerYearsRange: [-100, 3],
  /*default*/ defaultDatepickerNearbyMonthDays: true,
  /*default*/ defaultDatepickerNearbySelectableMonthDays: false,
}

export default buefyConfig
