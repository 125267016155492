<template>
  <section>
    <b-modal :active="true" has-modal-card @close="$router.back()">
      <div class="modal-card is-full">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit partner's games</p>
        </header>
        <section class="modal-card-body">
          <settings-form
            :settings="massiveGameSettings()"
            :selected-settings="settings"
            :editable="checkPermission(permission.administrator)"
          ></settings-form>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="$router.back()">
            Close
          </button>
          <button
            class="button is-primary"
            type="submit"
            @click.prevent="saveSettings"
          >
            Save
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import SettingsForm from '@/components/Settings/SettingsForm/SettingsForm'
import { mapState } from 'vuex'
import Game from '@/models/Game'
import Partner from '@/models/Partner'

export default {
  name: 'PartnerGamesSettings',
  components: { SettingsForm },
  props: {
    partnerUid: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      settings: {
        BetSettings: {
          added: [],
          removable: [],
        },
      },
    }
  },

  computed: {
    ...mapState(['loading']),
  },

  methods: {
    /**
     * Сохранить настройки.
     * @return void
     */
    saveSettings() {
      Partner.dispatch('putPartnerGamesSettings', {
        partnerUid: this.partnerUid,
        data: { settings: this.settings },
      }).then((message) => {
        this.$snackbar.open(message)
        this.$emit('partner-edited')
        this.$router.back()
      })
    },
    massiveGameSettings() {
      return Game.getters('getMassiveSettings')
    },
  },
  /**
   * @param to
   * @param from
   * @param next
   * @returns {Promise<void>}
   */
  beforeRouteEnter: async (to, from, next) => {
    await Game.dispatch('loadMassiveSettings')
    next()
  },
}
</script>
