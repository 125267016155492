import api from '@/helpers/api'

export default {
  /**
   * Загрузить банки.
   * @return {Promise<T | never>}
   */
  getBanks: () => api.sendRequest('get', 'bank'),

  /**
   * Загрузить банки для отчетов.
   * @return {Promise<T | never>}
   */
  getBanksForReports: () => api.sendRequest('get', 'bank/reports'),

  /**
   * Восстановить банк.
   * @param {int} bankId
   * @return {Promise<T | never>}
   */
  restoreBank: (bankId) => api.sendRequest('patch', `bank/${bankId}`, bankId),

  /**
   * Создать банк.
   * @param {object} bankData
   * @return {Promise<T | never>}
   */
  postBank: (bankData) => api.sendRequest('post', 'bank', bankData),

  /**
   * Редактировать банк.
   * @param {object} bankData
   * @return {Promise<T | never>}
   */
  putBank: (bankData) =>
    api.sendRequest('put', `bank/${bankData.bankId}`, bankData),
  /**
   * Загрузить валюты.
   * @return {Promise<T | never>}
   */
  getCurrency: () => api.sendRequest('get', 'currency'),

  getDefaultSettings: () => api.sendRequest('get', 'bank/settings'),
}
