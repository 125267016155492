<template>
  <section>
    <b-modal :active="true" has-modal-card @close.prevent="closeModal">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <b-icon class="has-text-danger" icon="alert-circle"></b-icon>
            <span>Delete game</span>
          </p>
        </header>
        <section class="modal-card-body">
          <p>
            Game ID: <strong>{{ game.id }}</strong>
          </p>
          <p>
            Title: <strong>{{ game.title }}</strong>
          </p>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click.prevent="closeModal">
            Close
          </button>
          <button
            class="button is-danger"
            type="submit"
            @click.prevent="deleteGame"
          >
            Delete
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Game from '@/models/Game'

export default {
  name: 'GameDelete',
  props: {
    game: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    deleteGame() {
      Game.dispatch('deleteGame', this.game.id).then((message) => {
        this.$snackbar.open(message)
        this.$emit('game-deleted')
        this.closeModal()
      })
    },
    closeModal() {
      this.$router.back()
    },
  },
}
</script>

<style scoped></style>
