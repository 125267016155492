import { Model } from '@vuex-orm/core'
import Game from '@/models/Game'

export default class GameType extends Model {
  static entity = 'gameType'

  static fields() {
    return {
      id: this.increment(),
      title: this.attr('Game type title'),
      games: this.hasMany(Game, 'gameTypeId'),
    }
  }
}
