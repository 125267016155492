import api from '@/helpers/api'

export default {
  /**
   * Залогиниться
   *
   * @param credentials
   * @return {Promise<{message: (*|string), token: string} | never>}
   */
  postLogin: (credentials) =>
    api.sendRequest('post', 'auth/login', credentials).then(
      (value) => value.token,
      (reason) => Promise.reject(reason)
    ),

  /**
   * Получить текущего пользователя
   *
   * @return {Promise<string | never>}
   */
  getMe: () =>
    api.sendRequest('get', 'auth/me').catch((reason) => Promise.reject(reason)),

  /**
   * Разлогиниться
   * @return {Promise<T | never>}
   */
  postLogout: () => api.sendRequest('post', 'auth/logout'),

  /**
   * Обновить токен
   *
   * @return {Promise<{expiresIn: (*|string), token: string} | never>}
   */
  postRefresh: () =>
    api.sendRequest('post', 'auth/refresh').then(
      (value) => value.token,
      (reason) => Promise.reject(reason)
    ),
}
