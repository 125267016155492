<template>
  <section>
    <b-modal :active="true" has-modal-card @close="closeModal">
      <div class="modal-card is-full">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ isEditing ? 'Edit partner' : 'Create partner' }}
          </p>
        </header>
        <section class="modal-card-body">
          <b-field
            v-if="!isEditing"
            label="UID"
            :message="errors.first('partnerUid')"
            :type="{ 'is-danger': errors.has('partnerUid') }"
          >
            <b-input
              ref="partnerUid"
              v-model="partnerUid"
              v-validate="{
                required: true,
                max: 8,
              }"
              name="partnerUid"
              data-vv-as="partner UID"
              placeholder="Partner UID"
              type="text"
            ></b-input>
          </b-field>
          <b-field
            label="URI"
            :message="errors.first('uri')"
            :type="{ 'is-danger': errors.has('uri') }"
          >
            <b-input
              v-model="uri"
              v-validate="{
                required: true,
              }"
              name="uri"
              data-vv-as="partner URI"
              placeholder="Partner URI"
              type="text"
            ></b-input>
          </b-field>
          <b-field
            label="Token"
            :message="errors.first('token')"
            :type="{ 'is-danger': errors.has('token') }"
          >
            <template slot="label">
              <span class="label">Token</span>
              <BCheckbox v-model="autoGenerateToken">
                Auto generate token
              </BCheckbox>
            </template>
            <b-input
              v-show="!autoGenerateToken"
              v-model="token"
              v-validate="{
                required: !autoGenerateToken,
              }"
              name="token"
              placeholder="Bearer token"
              type="text"
            ></b-input>
          </b-field>
          <b-field grouped>
            <b-field label="Active">
              <b-checkbox v-model="isActive"></b-checkbox>
            </b-field>
            <b-field
              label="Type"
              :message="errors.first('type')"
              :type="{ 'is-danger': errors.has('type') }"
            >
              <b-select
                v-model="integrationType"
                v-validate="{ required: true }"
                name="type"
                data-vv-as="integration type"
              >
                <option value="api">api</option>
                <option value="demo">demo</option>
              </b-select>
            </b-field>
          </b-field>
          <b-field label="Description">
            <b-input
              v-model="description"
              placeholder="Some description"
              type="textarea"
            ></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal">
            Close
          </button>
          <button
            class="button is-primary"
            type="submit"
            @click.prevent="savePartner()"
          >
            {{ isEditing ? 'Save' : 'Create' }}
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import validationErrors from '@/mixins/validationErrors'
import Partner from '@/models/Partner'

export default {
  name: 'NewPartner',

  mixins: [validationErrors],

  $_veeValidate: {
    validator: 'new',
  },

  props: {
    partner: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      partnerUid: this.partner.partnerUid,
      uri: this.partner.uri,
      token: this.partner.token,
      integrationType: this.partner.integrationType,
      description: this.partner.description,
      isActive: !!this.partner.isActive,
      isEditing: !this.$route.params.new,
      autoGenerateToken: this.$route.params.new,
    }
  },

  methods: {
    closeModal() {
      this.$router.back()
    },
    savePartner() {
      this.$validator
        .validateAll()
        .then((valid) => (valid ? Promise.resolve() : Promise.reject()))
        .then(
          () => {
            let partnerData = {
              partnerUid: this.partnerUid,
              uri: this.uri,
              autoGenerateToken: this.autoGenerateToken,
              token: this.token,
              description: this.description,
              integrationType: this.integrationType,
              isActive: this.isActive,
            }
            this.isEditing
              ? Partner.dispatch('putPartner', partnerData).then((message) => {
                  this.$emit('partner-created')
                  this.closeModal()
                  this.$snackbar.open(message)
                })
              : Partner.dispatch('postPartner', partnerData).then((message) => {
                  this.$emit('partner-created')
                  this.closeModal()
                  this.$snackbar.open(message)
                })
          },
          () => {
            this.$snackbar.open({
              message: "Form isn't valid. Please check the fields.",
              type: 'is-danger',
            })
          }
        )
    },
  },
}
</script>
