<template>
  <section>
    <b-modal active has-modal-card @close="closeModal">
      <div class="modal-card is-full">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Token for partner with UID: {{ partner.partnerUid }}
          </p>
        </header>
        <section class="modal-card-body">
          <b-input ref="partnerToken" :value="partner.token" readonly />
        </section>
        <footer class="modal-card-foot">
          <b-button label="Close" @click="closeModal" />
          <b-button label="Copy" type="is-info" @click="copyToken" />
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: 'PartnerToken',
  props: {
    partner: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  mounted() {
    if (!this.partner.partnerUid) {
      this.closeModal()
    }
  },
  methods: {
    closeModal() {
      this.$router.push({ name: 'partners' })
    },
    copyToken() {
      this.$refs.partnerToken.$el.querySelector('input').select()
      document.execCommand('copy')
      this.$notification.open('Token copied to clipboard')
    },
  },
}
</script>
