<template>
  <section>
    <section v-for="parameter in parameters" :key="parameter.title">
      <component
        :is="$_.kebabCase(parameter.name)"
        :title="parameter.title"
        :value="parameter.value"
        :stored.sync="sectionSettings[title][parameter.title]"
        :editable="editable"
      ></component>
    </section>
  </section>
</template>

<script>
import CappedPositiveIntegerParameter from '@/components/Settings/SettingsForm/Parameters/CappedPositiveIntegerParameter'
import OptedPositiveIntegerParameter from '@/components/Settings/SettingsForm/Parameters/OptedPositiveIntegerParameter'
import PositiveIntegerArrayParameter from '@/components/Settings/SettingsForm/Parameters/PositiveIntegerArrayParameter'
import OptionalPositiveIntegerArrayParameter from '@/components/Settings/SettingsForm/Parameters/OptionalPositiveIntegerArrayParameter.vue'
import PositiveIntegerParameter from '@/components/Settings/SettingsForm/Parameters/PositiveIntegerParameter'
import BooleanParameter from '@/components/Settings/SettingsForm/Parameters/BooleanParameter'

export default {
  name: 'SettingsSection',

  components: {
    'positive-integer-array-parameter': PositiveIntegerArrayParameter,
    'optional-positive-integer-array-parameter':
      OptionalPositiveIntegerArrayParameter,
    'positive-integer-parameter': PositiveIntegerParameter,
    'capped-positive-integer-parameter': CappedPositiveIntegerParameter,
    'opted-positive-integer-parameter': OptedPositiveIntegerParameter,
    'boolean-parameter': BooleanParameter,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    parameters: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    editable: {
      type: Boolean,
    },
  },

  data() {
    return {
      sectionSettings: this.settings,
    }
  },

  watch: {
    sectionSettings() {
      this.$emit('update:settings')
    },
  },
}
</script>
