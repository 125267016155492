/**
 * Перечень action'ов - запросов к api,
 * при которых надо устанавливать в хранилище флаг загрузки
 *
 * @param {object} store
 */
export default (store) => {
  const actionsForSubscribe = [
    'entities/users/loadUsers',
    'entities/users/loadUserRoles',
    'entities/partners/loadPartners',
    'entities/games/loadGames',
    'entities/games/loadGameTypes',
    'entities/banks/loadBanks',
  ]
  store.subscribeAction({
    before: (action, state) => {
      if (actionsForSubscribe.includes(action.type)) {
        state.loading = true
      }
    },
    after: (action, state) => {
      if (actionsForSubscribe.includes(action.type)) {
        state.loading = false
      }
    },
  })
}
