import Buefy from 'buefy'
import lodash from 'lodash'
import VueRouter from 'vue-router'
import VeeValidate from 'vee-validate'
import Vue from 'vue'
import App from './App'
import axios from './helpers/axios'
import buefyConfig from './configs/buefyConfig'
import permissions from './mixins/permissions'
import router from './router'
import store from './store/store'
import veeValidateConfig from './configs/veeValidateConfig'

Vue.use(VueRouter)
Vue.use(Buefy, buefyConfig)
Vue.use(VeeValidate, veeValidateConfig)
Vue.use(axios)

window.moment = require('moment')

Vue.mixin(permissions)

Vue.config.productionTip = !!process.env.VUE_APP_PRODUCTION_TIP
Vue.config.devtools = !!process.env.VUE_APP_DEVTOOLS

Vue.prototype.$_ = lodash

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
