import { Model } from '@vuex-orm/core'

export default class AuthUser extends Model {
  static entity = 'authUser'

  static fields() {
    return {
      id: this.number(0),
      username: this.attr(''),
      email: this.attr(''),
      partnerUid: this.attr(''),
      isActive: this.boolean(false),
      deletedAt: this.attr(''),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
      // roles: this.belongsToMany(Role, RoleUser, 'userId', 'roleId'),
      // roleUsers: this.hasMany(RoleUser, 'userId'),
      // partner: this.hasOne(Partner, 'partnerUid', 'partnerUid'),
    }
  }
}
