import api from '@/api/partners.api'
import Partner from '@/models/Partner'
import Game from '@/models/Game'

export default {
  namespaced: true,

  state: {
    perpage: 10,
  },

  actions: {
    /**
     * Получить партнёров
     * @return {Promise<T | never>}
     */
    loadPartners: () =>
      api.getPartners().then((partners) =>
        Partner.insertOrUpdate({
          data: Array.isArray(partners) ? partners : [],
        })
      ),

    /**
     * Изменить партнёра
     * @param context
     * @param partnerData
     * @return {*|Promise<T | never>}
     */
    putPartner: (context, partnerData) => api.putPartner(partnerData),

    /**
     * Создать партнёра
     * @param context
     * @param partnerData
     * @return {*|Promise<T|never>}
     */
    postPartner: (context, partnerData) => api.postPartner(partnerData),

    /**
     * Загрузить список игр партёнра.
     * @param context
     * @param partnerUid
     * @return {Promise<T | never>}
     */
    loadPartnerGames: (context, partnerUid) =>
      api
        .getPartnerGames(partnerUid)
        .then((games) =>
          Game.insertOrUpdate({ data: Array.isArray(games) ? games : [] })
        ),

    /**
     * Изменить статус активности игры партнёру.
     * @param {object} context
     * @param {string} partnerUid
     * @param {integer} gameId
     * @param {boolean} value
     * @returns {*}
     */
    patchPartnerGames: (context, { partnerUid, gameId, value }) =>
      api.patchPartnerGames({ partnerUid, gameId, value }),

    /**
     * Обновить список игр партнёра.
     * @param context
     * @param {object} partnerData
     * @return {*}
     */
    putPartnerGames: (context, partnerData) =>
      api.putPartnerGames(partnerData.partnerUid, partnerData.data),

    /**
     * Обновить настройки игр партнёра.
     * @param context
     * @param {object} partnerData
     * @return {*}
     */
    putPartnerGamesSettings: (context, partnerData) =>
      api.putPartnerGamesSettings(partnerData.partnerUid, partnerData.data),
  },

  getters: {
    getPerpage: (state) => state.perpage,
  },
}
