import VuexORM from '@vuex-orm/core'
import Vuex from 'vuex'
import database from './database'
import loadingPlugin from '@/helpers/loading'

export default new Vuex.Store({
  state: {
    headerVisibility: true,
    loading: false,
  },
  mutations: {
    toggleHeader: (state, isVisible) => (state.headerVisibility = isVisible),
    toggleLoading: (state, payload) => (state.loading = payload),
  },
  plugins: [VuexORM.install(database), loadingPlugin],
})
