import RoleUser from '@/models/RoleUser'
import User from '@/models/User'
import { Model } from '@vuex-orm/core'
import RoleGroup from '@/models/RoleGroup'

export default class Role extends Model {
  static entity = 'roles'

  static fields() {
    return {
      id: this.number(0),
      title: this.attr(''),
      groupId: this.number(0),
      roleUsers: this.hasMany(RoleUser, 'roleId'),
      users: this.belongsToMany(User, RoleUser, 'roleId', 'userId'),
      roleGroup: this.belongsTo(RoleGroup, 'groupId'),
    }
  }
}
