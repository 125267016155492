<template>
  <section>
    <b-modal :active="true" has-modal-card @close="closeModal">
      <div class="modal-card is-full">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ $route.params.new ? 'Create bank' : 'Edit bank' }}
          </p>
        </header>
        <section class="modal-card-body">
          <b-field label="Description">
            <b-input
              ref="description"
              v-model="description"
              placeholder="Type description"
              type="text"
            >
            </b-input>
          </b-field>
          <settings-form
            :settings="defaultSettings"
            :selected-settings.sync="bankDefaultSettings"
          >
          </settings-form>
          <b-field grouped group-multiline>
            <b-field v-if="!isEditing" label="Currency">
              <b-select v-model="currency" :loading="loading" required>
                <option
                  v-for="(currencyType, index) in currencies"
                  :key="index"
                  :value="currencyType"
                >
                  {{ currencyType.title }}
                </option>
              </b-select>
            </b-field>
            <b-field
              v-if="checkPermission(permission.partnersAccess) && !isEditing"
              label="Partner UID"
            >
              <b-select v-model="partnerUid" :loading="loading" required>
                <option value="NONE">NONE</option>
                <option
                  v-for="partner in partners"
                  :key="partner.partnerUid"
                  :value="partner.partnerUid"
                >
                  {{ partner.partnerUid }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Active">
              <b-checkbox v-model="isActive"></b-checkbox>
            </b-field>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal">
            Close
          </button>
          <button class="button is-primary" type="submit" @click="saveBank">
            {{ $route.params.new ? 'Create' : 'Save' }}
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import SettingsForm from '@/components/Settings/SettingsForm/SettingsForm'
import AuthUser from '@/models/AuthUser'
import Bank from '@/models/Bank'
import Currency from '@/models/Currency'
import Partner from '@/models/Partner'
import { mapState } from 'vuex'

export default {
  name: 'BankForm',

  components: { 'settings-form': SettingsForm },

  $_veeValidate: {
    validator: 'new',
  },

  props: {
    bank: {
      type: Object,
      default: () => ({
        description: '',
        partnerUid: '',
        currency: '',
      }),
    },
  },

  data() {
    return {
      isEditing: !this.$route.params.new,
      bankId: this.bank.id,
      description: this.bank.description,
      partnerUid: this.bank.bankPartnerUid,
      isActive: this.bank.isActive,
      currency: this.bank.currency,
      bankDefaultSettings: this.bank.defaultSettings,
    }
  },

  computed: {
    /**
     * Залогиненый пользователь
     * @returns {Item<AuthUser>}
     */
    authUser: () => AuthUser.query().first(),
    /**
     * Все доступные валюты.
     * @returns {Collection<InstanceOf<Currency>>}
     */
    currencies: () => Currency.all(),
    /**
     * Все партнёры.
     * @returns {Collection<InstanceOf<Partner>>}
     */
    partners: () => Partner.all(),
    ...mapState(['loading']),
    /**
     * Объект с параметрами и секциями настроек.
     * @returns {any}
     */
    defaultSettings: () => Bank.getters('getDefaultSettings'),
  },

  mounted() {
    if (this.checkPermission(this.permission.partnersAccess)) {
      Partner.dispatch('loadPartners')
    }
    Bank.dispatch('loadCurrency')
  },

  methods: {
    saveBank() {
      this.$validator
        .validateAll()
        .then((valid) => (valid ? Promise.resolve() : Promise.reject()))
        .then(
          () => {
            let bankData = {
              bankId: this.bankId,
              bank_partner_uid:
                this.partnerUid === 'NONE' ? null : this.partnerUid,
              description: this.description,
              is_active: this.isActive,
              currency_id: this.currency.id,
              currency_settings: this.bankDefaultSettings.CurrencySettings,
              limit_settings: this.bankDefaultSettings.LimitSettings,
            }
            if (this.isEditing) {
              Bank.dispatch('putBank', bankData).then(() => {
                this.$emit('bank-created')
                this.closeModal()
              })
            } else {
              Bank.dispatch('postBank', bankData).then(() => {
                this.$emit('bank-created')
                this.closeModal()
              })
            }
          },
          () => this.$snackbar.open({ type: 'is-danger', message: 'Errors' })
        )
    },

    closeModal() {
      if (this.$route.params.new) {
        Bank.delete(this.bank.id)
      }
      this.$router.back()
    },
  },

  async beforeRouteEnter(to, from, next) {
    if (to.params.new) {
      await Bank.new().then((bank) => (to.params.bank = bank))
    }
    next()
  },
}
</script>
