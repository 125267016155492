import api from '@/helpers/api'

export default {
  /**
   * Получить партнёров
   * @returns {*}
   */
  getPartners: () => api.sendRequest('get', 'partner'),

  /**
   * Получить партнёров для отчетов
   * @returns {*}
   */
  getPartnersForReports: () => api.sendRequest('get', 'partner/reports'),

  /**
   * Получить игры партнёров для отчетов
   * @returns {*}
   */
  getPartnersGamesForReports: () =>
    api.sendRequest('get', 'partner/games/reports'),

  /**
   * Оредактировать партнёра
   *
   * @param partnerData
   * @returns {*}
   */
  putPartner: (partnerData) =>
    api.sendRequest('put', `partner/${partnerData.partnerUid}`, partnerData),

  /**
   * Создать партнёра
   *
   * @param partnerData
   * @returns {*}
   */
  postPartner: (partnerData) => api.sendRequest('post', 'partner', partnerData),

  /**
   * Получить список игр партнёра.
   * @param {string} partnerUid
   * @returns {*}
   */
  getPartnerGames: (partnerUid) =>
    api.sendRequest('get', `partner/${partnerUid}/games`),

  /**
   * Изменить статус активности игры партнёру.
   * @param {string} partnerUid
   * @param {integer} gameId
   * @param {boolean} value
   * @returns {*}
   */
  patchPartnerGames: ({ partnerUid, gameId, value }) =>
    api.sendRequest('patch', `partner/${partnerUid}/game/${gameId}`, {
      value: value,
    }),

  /**
   * Обновить список игр партнёра.
   * @param {string} partnerUid
   * @param {object} games
   * @returns {*}
   */
  putPartnerGames: (partnerUid, games) =>
    api.sendRequest('put', `partner/${partnerUid}/games`, games),

  putPartnerGamesSettings: (partnerUid, games) =>
    api.sendRequest('put', `partner/${partnerUid}/games-settings`, games),
}
