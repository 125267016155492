import { Model } from '@vuex-orm/core'

export default class Currency extends Model {
  static entity = 'currencies'

  static fields() {
    return {
      id: this.increment(),
      title: this.attr(''),
    }
  }
}
