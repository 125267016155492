import { Model } from '@vuex-orm/core'
import User from '@/models/User'
import dateParser from '@/helpers/dateParser'
import Game from '@/models/Game'
import GamePartner from '@/models/GamePartner'
import Bank from '@/models/Bank'

export default class Partner extends Model {
  static entity = 'partners'

  static primaryKey = 'partnerUid'

  static fields() {
    return {
      partnerUid: this.attr('Partner UID'),
      uri: this.attr('Partner URI'),
      token: this.attr('Partner token'),
      integrationType: this.attr('Integration type'),
      isActive: this.boolean(false),
      description: this.attr('Partner description.'),
      createdAt: this.attr(Date.now().toString(), (date) =>
        dateParser.beautifyDate(date)
      ),
      updatedAt: this.attr(Date.now().toString(), (date) =>
        dateParser.beautifyDate(date)
      ),
      deletedAt: this.attr(null, (date) => dateParser.beautifyDate(date)),
      users: this.hasMany(User, 'partnerUid', 'partnerUid'),
      gamePartners: this.hasMany(GamePartner, 'gamePartnerUid'),
      games: this.belongsToMany(Game, GamePartner, 'gamePartnerUid', 'gameId'),
      bank: this.hasOne(Bank, 'bankPartnerUid', 'partnerUid'),
    }
  }
}
