<template>
  <section>
    <b-modal :active="true" has-modal-card @close="closeModal">
      <form @submit.prevent="saveUser">
        <div class="modal-card is-full">
          <header class="modal-card-head">
            <p class="modal-card-title">
              {{ isEditing ? 'Edit user' : 'Create user' }}
            </p>
          </header>
          <section class="modal-card-body">
            <b-field
              :message="errors.first('username')"
              :type="{ 'is-danger': errors.has('username') }"
              label="Username"
            >
              <b-input
                v-model="username"
                v-validate="{
                  required: true,
                  min: 5,
                  max: 16,
                  regex: /^[a-zA-Z0-9_-]{5,16}$/,
                }"
                name="username"
                placeholder="Type username"
                readonly
                type="text"
                @focus="disableReadonly"
              >
              </b-input>
            </b-field>
            <b-field
              :messsage="errors.first('email')"
              :type="{ 'is-danger': errors.has('email') }"
              label="Email"
            >
              <b-input
                v-model="email"
                v-validate="'required|email'"
                name="email"
                placeholder="Type email address"
                readonly
                type="email"
                @focus="disableReadonly"
              >
              </b-input>
            </b-field>
            <b-switch v-if="isEditing" v-model="changePassword">
              Change password
            </b-switch>
            <b-field
              v-if="
                changePassword &&
                isEditing &&
                !checkPermission(permission.administrator)
              "
              :message="errors.first('oldPassword')"
              :type="{ 'is-danger': errors.has('oldPassword') }"
              label="Old Password"
            >
              <b-input
                v-model="oldPassword"
                v-validate="'required|min:8'"
                data-vv-as="old password"
                name="oldPassword"
                password-reveal
                placeholder="Type old password"
                type="password"
              >
              </b-input>
            </b-field>
            <b-field
              v-if="changePassword"
              :message="errors.first('password')"
              :type="{ 'is-danger': errors.has('password') }"
              label="Password"
            >
              <b-input
                ref="password"
                v-model="password"
                v-validate="'required|min:8'"
                name="password"
                password-reveal
                placeholder="Type password"
                readonly
                type="password"
                @focus="disableReadonly"
              >
              </b-input>
            </b-field>
            <b-field
              v-if="changePassword"
              :message="errors.first('password_confirmation')"
              :type="{ 'is-danger': errors.has('password_confirmation') }"
              label="Password confirm"
            >
              <b-input
                v-model="passwordConfirm"
                v-validate="'required|min:8|confirmed:password'"
                data-vv-as="password confirmation"
                name="password_confirmation"
                password-reveal
                placeholder="Type password again"
                readonly
                type="password"
                @focus="disableReadonly"
              >
              </b-input>
            </b-field>
            <b-field
              v-if="
                checkPermission([
                  permission.administrator,
                  permission.superuser,
                ]) && userId !== authUser.id
              "
              grouped
            >
              <b-field label="Active">
                <b-checkbox v-model="isActive"></b-checkbox>
              </b-field>
              <b-field
                v-if="
                  checkPermission([
                    permission.administrator,
                    permission.superuser,
                  ]) && !isEditing
                "
                :message="errors.first('partner')"
                :type="{ 'is-danger': errors.has('partner') }"
                label="Partner UID"
              >
                <b-select
                  v-model="partnerUid"
                  v-validate="'required'"
                  :loading="loading"
                  name="partner"
                >
                  <option value="NONE">NONE</option>
                  <option
                    v-for="partner in partners"
                    :key="partner.partnerUid"
                    :value="partner.partnerUid"
                  >
                    {{ partner.partnerUid }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Close
            </button>
            <button class="button is-primary" type="submit">
              {{ isEditing ? 'Save' : 'Create' }}
            </button>
            <router-link
              v-if="userId !== authUser.id && authUserHasRolesForDelegating"
              :to="{
                name: 'editRoles',
                params: { user: user },
              }"
              class="button is-rounded is-primary"
              type="button"
            >
              <span>Assign roles</span>
            </router-link>
          </footer>
        </div>
      </form>
    </b-modal>
    <router-view></router-view>
  </section>
</template>

<script>
import validationErrors from '@/mixins/validationErrors'
import AuthUser from '@/models/AuthUser'
import Partner from '@/models/Partner'
import Role from '@/models/Role'
import User from '@/models/User'
import RoleUser from '@/models/RoleUser'
import RoleGroup from '@/models/RoleGroup'
import { mapState } from 'vuex'

export default {
  name: 'UserForm',

  mixins: [validationErrors],

  $_veeValidate: {
    validator: 'new',
  },

  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      userId: this.user.id,
      username: this.user.username,
      email: this.user.email,
      password: '',
      passwordConfirm: '',
      partnerUid: this.user.partnerUid,
      isActive: !!this.user.isActive,
      oldPassword: '',
      isEditing: !this.$route.params.new,
      changePassword: !!this.$route.params.new,
    }
  },

  computed: {
    authUser: () => AuthUser.query().first(),
    ...mapState(['loading']),
    partners: () => Partner.all(),
    authUserHasRolesForDelegating: () =>
      RoleUser.query()
        .where('userId', AuthUser.query().first().id)
        .where('delegate', true)
        .all().length > 0,
  },

  watch: {
    changePassword() {
      this.password = ''
      this.passwordConfirm = ''
    },
  },

  mounted() {
    if (
      this.checkPermission([
        this.permission.administrator,
        this.permission.superuser,
      ])
    ) {
      Partner.dispatch('loadPartners')
    }
    if (this.isEditing && this.userId !== this.authUser.id) {
      User.dispatch('loadUserRoles', this.userId)
    }
    User.dispatch('loadUserRoles', this.authUser.id)
  },

  methods: {
    closeModal() {
      if (!this.isEditing) {
        User.delete(this.userId)
      }
      this.$router.back()
    },
    saveUser() {
      this.$validator
        .validateAll()
        .then((valid) => (valid ? Promise.resolve() : Promise.reject()))
        .then(
          () => {
            let userData = {
              userId: this.userId,
              username: this.username,
              email: this.email,
              user_partner_uid:
                this.partnerUid === 'NONE' ? null : this.partnerUid,
              is_active: this.isActive,
              old_password: this.oldPassword,
              roles: RoleUser.query().where('userId', this.userId).get(),
            }
            if (this.changePassword) {
              userData.password = this.password
              userData.password_confirmation = this.passwordConfirm
            }
            this.isEditing
              ? User.dispatch('putUser', userData).then((message) => {
                  this.$snackbar.open(message)
                  this.$emit('user-created')
                  this.closeModal()
                })
              : User.dispatch('postUser', userData).then((message) => {
                  this.$snackbar.open(message)
                  this.$emit('user-created')
                  this.closeModal()
                })
          },
          () => {
            this.$snackbar.open({
              message: "Form isn't valid. Please check the fields.",
              type: 'is-danger',
            })
          }
        )
    },

    /**
     * Это чтобы в хроме в поля ввода не вставлялись сохранённые хромом данные
     */
    disableReadonly() {
      this.$el
        .querySelectorAll('[readonly="readonly"]')
        .forEach((input) => input.removeAttribute('readonly'))
    },
  },

  async beforeRouteEnter(to, from, next) {
    if (to.params.new) {
      await User.new().then((user) => (to.params.user = user))
    }
    next()
  },

  beforeRouteLeave(to, from, next) {
    Promise.all([
      RoleUser.deleteAll(),
      Role.deleteAll(),
      RoleGroup.deleteAll(),
    ]).then(() => next())
  },
}
</script>
