import Vue from 'vue'

export default {
  /**
   * Отправить запрос
   *
   * @param {string} method
   * @param {string} url
   * @param {object|null} data
   * @returns {*}
   */
  sendRequest: (method, url, data) =>
    Vue.axios({
      method: method,
      url: url,
      data: data,
    }),
}
