var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-modal',{attrs:{"active":true,"has-modal-card":""},on:{"close":_vm.closeModal}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveUser.apply(null, arguments)}}},[_c('div',{staticClass:"modal-card is-full"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Edit user' : 'Create user')+" ")])]),_c('section',{staticClass:"modal-card-body"},[_c('b-field',{attrs:{"message":_vm.errors.first('username'),"type":{ 'is-danger': _vm.errors.has('username') },"label":"Username"}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: true,
                min: 5,
                max: 16,
                regex: /^[a-zA-Z0-9_-]{5,16}$/,
              }),expression:"{\n                required: true,\n                min: 5,\n                max: 16,\n                regex: /^[a-zA-Z0-9_-]{5,16}$/,\n              }"}],attrs:{"name":"username","placeholder":"Type username","readonly":"","type":"text"},on:{"focus":_vm.disableReadonly},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('b-field',{attrs:{"messsage":_vm.errors.first('email'),"type":{ 'is-danger': _vm.errors.has('email') },"label":"Email"}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"name":"email","placeholder":"Type email address","readonly":"","type":"email"},on:{"focus":_vm.disableReadonly},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),(_vm.isEditing)?_c('b-switch',{model:{value:(_vm.changePassword),callback:function ($$v) {_vm.changePassword=$$v},expression:"changePassword"}},[_vm._v(" Change password ")]):_vm._e(),(
              _vm.changePassword &&
              _vm.isEditing &&
              !_vm.checkPermission(_vm.permission.administrator)
            )?_c('b-field',{attrs:{"message":_vm.errors.first('oldPassword'),"type":{ 'is-danger': _vm.errors.has('oldPassword') },"label":"Old Password"}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:8'),expression:"'required|min:8'"}],attrs:{"data-vv-as":"old password","name":"oldPassword","password-reveal":"","placeholder":"Type old password","type":"password"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1):_vm._e(),(_vm.changePassword)?_c('b-field',{attrs:{"message":_vm.errors.first('password'),"type":{ 'is-danger': _vm.errors.has('password') },"label":"Password"}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:8'),expression:"'required|min:8'"}],ref:"password",attrs:{"name":"password","password-reveal":"","placeholder":"Type password","readonly":"","type":"password"},on:{"focus":_vm.disableReadonly},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1):_vm._e(),(_vm.changePassword)?_c('b-field',{attrs:{"message":_vm.errors.first('password_confirmation'),"type":{ 'is-danger': _vm.errors.has('password_confirmation') },"label":"Password confirm"}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:8|confirmed:password'),expression:"'required|min:8|confirmed:password'"}],attrs:{"data-vv-as":"password confirmation","name":"password_confirmation","password-reveal":"","placeholder":"Type password again","readonly":"","type":"password"},on:{"focus":_vm.disableReadonly},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}})],1):_vm._e(),(
              _vm.checkPermission([
                _vm.permission.administrator,
                _vm.permission.superuser ]) && _vm.userId !== _vm.authUser.id
            )?_c('b-field',{attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Active"}},[_c('b-checkbox',{model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})],1),(
                _vm.checkPermission([
                  _vm.permission.administrator,
                  _vm.permission.superuser ]) && !_vm.isEditing
              )?_c('b-field',{attrs:{"message":_vm.errors.first('partner'),"type":{ 'is-danger': _vm.errors.has('partner') },"label":"Partner UID"}},[_c('b-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"loading":_vm.loading,"name":"partner"},model:{value:(_vm.partnerUid),callback:function ($$v) {_vm.partnerUid=$$v},expression:"partnerUid"}},[_c('option',{attrs:{"value":"NONE"}},[_vm._v("NONE")]),_vm._l((_vm.partners),function(partner){return _c('option',{key:partner.partnerUid,domProps:{"value":partner.partnerUid}},[_vm._v(" "+_vm._s(partner.partnerUid)+" ")])})],2)],1):_vm._e()],1):_vm._e()],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(" Close ")]),_c('button',{staticClass:"button is-primary",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Save' : 'Create')+" ")]),(_vm.userId !== _vm.authUser.id && _vm.authUserHasRolesForDelegating)?_c('router-link',{staticClass:"button is-rounded is-primary",attrs:{"to":{
              name: 'editRoles',
              params: { user: _vm.user },
            },"type":"button"}},[_c('span',[_vm._v("Assign roles")])]):_vm._e()],1)])])]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }