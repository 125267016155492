<template>
  <b-field
    :message="errors.first(title)"
    :type="{ 'is-danger': errors.has(title) }"
  >
    <p class="control">
      <span class="button is-static">{{ title }}</span>
    </p>
    <b-input
      v-model="selectedValue"
      v-validate="{
        required: true,
        max_value: value,
        min_value: 1,
        integer: true,
      }"
      :name="title"
      type="number"
      :max="value"
      :min="1"
      :disabled="!editable"
      @input="parseInteger"
    ></b-input>
  </b-field>
</template>

<script>
export default {
  name: 'CappedPositiveIntegerParameter',

  inject: ['$validator'],

  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: null,
    },
    stored: {
      type: Number,
      default: undefined,
    },
    editable: {
      type: Boolean,
    },
  },

  data() {
    return {
      selectedValue: this.stored,
    }
  },

  watch: {
    selectedValue() {
      this.$emit('update:stored', this.selectedValue)
    },
  },

  methods: {
    parseInteger() {
      this.selectedValue = parseInt(this.selectedValue)
    },
  },
}
</script>
