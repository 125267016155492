import api from '@/api/games.api'
import Game from '@/models/Game'
import GameType from '@/models/GameType'

export default {
  namespaced: true,

  state: {
    perpage: 10,
    defaultGameSettingsStructure: {},
    defaultMassiveGameSettingsStructure: {},
  },

  actions: {
    /**
     * Загрузить игры
     * @return {Promise<Collections<InstanceOf<Game>> | never>}
     */
    loadGames: () =>
      api
        .getGames()
        .then((games) =>
          Game.insertOrUpdate({ data: Array.isArray(games) ? games : [] })
        ),

    /**
     * Создать игру
     * @param context
     * @param {object} gameData
     * @return {*|Promise<T|never>}
     */
    postGame: (context, gameData) => api.postGame(gameData),

    /**
     * Изменить игру.
     * @param context
     * @param {object} gameData
     * @return {*|Promise<T|never>}
     */
    putGame: (context, gameData) => api.putGame(gameData),

    /**
     * Удалить игру.
     * @param context
     * @param {int} gameId
     * @return {*|Promise<T|never>}
     */
    deleteGame: (context, gameId) => api.deleteGame(gameId),

    /**
     * Загрузить список типов игр.
     * @return {Promise<T | never>}
     */
    loadGameTypes: () =>
      api
        .getGameTypes()
        .then((types) => GameType.insertOrUpdate({ data: types })),

    saveGameSettings: (context, gameData) => api.postGameSettings(gameData),

    saveMassiveGameSettings: (context, gameData) =>
      api.postMassiveGameSettings(gameData),

    loadDefaultSettings: ({ state }) =>
      api
        .getDefaultSettings()
        .then((settings) => (state.defaultGameSettingsStructure = settings)),

    loadMassiveSettings: ({ state }) =>
      api
        .getMassiveSettings()
        .then(
          (settings) => (state.defaultMassiveGameSettingsStructure = settings)
        ),
  },

  getters: {
    getPerpage: (state) => state.perpage,

    getDefaultSettings: (state) => state.defaultGameSettingsStructure,

    getMassiveSettings: (state) => state.defaultMassiveGameSettingsStructure,
  },
}
