import api from '@/api/users.api'
import User from '@/models/User'
import Role from '@/models/Role'

export default {
  namespaced: true,

  state: {
    perpage: 10,
  },

  actions: {
    /**
     * Получить пользователей
     * @returns {Promise<Collections<InstanceOf<User>> | never>}
     */
    loadUsers: () =>
      api
        .getUsers()
        .then((users) =>
          User.insertOrUpdate({ data: Array.isArray(users) ? users : [users] })
        ),

    /**
     * Создать пользователя
     * @param context
     * @param userData
     * @return {Promise<T | never>}
     */
    postUser: (context, userData) => api.postUser(userData),

    /**
     * Изменить пользователя
     * @param context
     * @param userData
     * @return {Promise<T | never>}
     */
    putUser: (context, userData) => api.putUser(userData),

    /**
     * Удалить пользователя
     * @param context
     * @param userId
     * @return {Promise<T | never>}
     */
    deleteUser: (context, userId) => api.deleteUser(userId),

    /**
     * Восстановить пользователя
     * @param context
     * @param userId
     * @return {Promise<void | never>}
     */
    restoreUser: (context, userId) => api.restoreUser(userId),

    /**
     * Получить роли пользователя
     * @param context
     * @param {int} userId
     * @return {Promise<T | never>}
     */
    loadUserRoles: (context, userId) =>
      api
        .getUserRole(userId)
        .then((roles) => Role.insertOrUpdate({ data: roles })),
  },

  getters: {
    getPerpage: (state) => state.perpage,
  },
}
