export default {
  hsl: { h: 0, s: 1, l: 0.5, a: 1 },
  hex: '#FF0000',
  hex8: '#FF0000FF',
  rgba: { r: 255, g: 0, b: 0, a: 1 },
  hsv: { h: 0, s: 1, v: 1, a: 1 },
  oldHue: 150,
  source: 'hex',
  a: 1,
}
