<template>
  <section>
    <b-field v-for="section in sections" :key="section" :label="section">
      <template slot="label">
        <b-checkbox
          v-if="appendableSections[section]"
          v-model="visibleSections[section]"
          @input="toggleSection(section)"
        >
          {{ $_.startCase(section) }}
        </b-checkbox>
      </template>
      <settings-section
        v-if="visibleSections[section]"
        :title="section"
        :parameters="settings[section]"
        :settings="renderedSettings"
        :editable="editable"
      >
      </settings-section>
    </b-field>
  </section>
</template>

<script>
import SettingsSection from '@/components/Settings/SettingsForm/SettingsSection'

export default {
  name: 'SettingsForm',

  components: { 'settings-section': SettingsSection },

  inject: ['$validator'],

  provide() {
    return {
      $validator: this.$validator,
    }
  },

  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
    selectedSettings: {
      type: Object,
      required: true,
    },
    appendableSections: {
      type: Array,
      default: () => [],
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    setSettingsSections: {
      type: Function,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      visibleSections: {},
      renderedSettings: {},
    }
  },

  computed: {
    sections() {
      return this.$_.map(this.settings, (section, sectionName) => sectionName)
    },
  },

  created() {
    this.sections.forEach(
      (section) =>
        (this.visibleSections[section] = !(
          this.appendableSections[section] && this.isEditing
        ))
    )
    this.renderedSettings = this.getRenderedSettings(
      this.selectedSettings,
      this.visibleSections
    )
  },

  methods: {
    toggleSection(sectionName) {
      this.$forceUpdate()
      this.renderedSettings = this.getRenderedSettings(
        this.selectedSettings,
        this.visibleSections
      )
      this.setSettingsSections(this.renderedSettings)
    },
    getRenderedSettings(allSettings, visibleSections) {
      return this.$_.pickBy(allSettings, (value, key) => {
        return visibleSections[key]
      })
    },
  },
}
</script>
