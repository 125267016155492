<template>
  <section>
    <b-modal :active="true" has-modal-card @close="$router.back()">
      <div class="modal-card is-full">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit roles</p>
        </header>
        <section class="modal-card-body is-paddingless">
          <role-picker-group
            v-for="roleGroup in roleGroups"
            :key="roleGroup.id"
            :roles-group="roleGroup"
            :roles="roleGroup.roles"
            :title="roleGroup.title"
            :user="user"
          ></role-picker-group>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-rounded is-primary"
            role="button"
            @click="$router.back()"
          >
            Back
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import AuthUser from '@/models/AuthUser'
import RolePickerGroup from './RolePickerGroup'
import RoleGroup from '@/models/RoleGroup'

export default {
  name: 'RolePicker',
  components: { RolePickerGroup },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    authUser: () => AuthUser.query().first(),

    /**
     * Группы ролей с ролями, которые можно делегировать
     * @return {RoleGroup[]}
     */
    roleGroups: () =>
      RoleGroup.query()
        .with('roles', (query) =>
          query.whereHas('roleUsers', (query1) =>
            query1
              .where('delegate', true)
              .where('userId', AuthUser.query().first().id)
          )
        )
        .get()
        .filter((roleGroup) => roleGroup.roles.length > 0),
  },
}
</script>
