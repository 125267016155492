import api from '@/api/auth.api'
import _ from 'lodash'
import authUser from '@/models/AuthUser'

export default {
  namespaced: true,
  state: {
    token: null,
    expiresIn: null,
    userPermissions: [],
  },

  actions: {
    /**
     * Залогиниться
     *
     * @param dispatch
     * @param credentials
     * @return {Promise<T | never>}
     */
    login: ({ dispatch }, credentials) =>
      api
        .postLogin(credentials)
        .then((token) =>
          dispatch('setTokenData', token).then(() => dispatch('getMe'))
        ),
    /**
     * Разлогиниться
     *
     * @param dispatch
     * @return {Promise<any> | Promise<void | never>}
     */
    logout: ({ dispatch }) =>
      api.postLogout().finally(() => dispatch('removeDataAndLogout')),
    /**
     * Обновить токен
     *
     * @param dispatch
     * @return {Promise<T | never>}
     */
    refresh: ({ dispatch }) =>
      api.postRefresh().then(
        (token) => {
          dispatch('setTokenData', token)
          dispatch('getMe')
        },
        () => dispatch('removeDataAndLogout').then(() => Promise.reject())
      ),
    /**
     * Получить залогиненого пользователя
     * @param commit
     * @return {Promise<T | never>}
     */
    getMe: ({ commit }) =>
      api.getMe().then((user) => {
        localStorage.setItem('user', JSON.stringify(user))
        commit('setLoggedUser', user)
      }),

    setTokenData({ commit }, token) {
      let base64Url = token.split('.')[1]
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      let tokenPayload = JSON.parse(window.atob(base64))
      let expiresIn = tokenPayload.exp
      let userPermissions = _.toArray(tokenPayload.prm)
      localStorage.setItem('token', token)
      localStorage.setItem('expiresIn', expiresIn)
      commit('setBearerToken', token)
      commit('setExpiresIn', expiresIn)
      commit('setUserPermissions', userPermissions)
    },

    removeDataAndLogout({ commit, dispatch }) {
      dispatch('entities/deleteAll', {}, { root: true })
      localStorage.clear()

      commit('setLoggedUser', null)
      commit('setBearerToken', null)
      commit('setExpiresIn', null)
      commit('setUserPermissions', [])
    },

    /**
     * Проверить время "протухания" токена и обновить токен
     * @param dispatch
     * @param getters
     * @return {Promise<any>}
     */
    checkExpiresIn: async ({ dispatch, getters }) =>
      await new Promise((resolve, reject) =>
        getters.getExpiresIn < Math.floor(Date.now() / 1000)
          ? dispatch('refresh')
              .catch((reason) => reject(reason))
              .then(() => resolve())
          : resolve()
      ),
  },

  mutations: {
    setLoggedUser: (state, user) => {
      if (user) {
        authUser.insertOrUpdate({ data: user })
      } else {
        authUser.deleteAll()
      }
      return (state.user = user)
    },

    setBearerToken: (state, token) => (state.token = token),

    setExpiresIn: (state, expires) => (state.expiresIn = expires),

    setUserPermissions: (state, perms) => (state.userPermissions = perms),
  },

  getters: {
    getToken: (state) => state.token || localStorage.getItem('token'),

    getExpiresIn: (state) =>
      state.expiresIn || localStorage.getItem('expiresIn'),

    getUserPermissions: (state) => _.toArray(state.userPermissions),

    getAuthUser: (state) =>
      state.user || JSON.parse(localStorage.getItem('user')),
  },
}
