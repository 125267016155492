import { Model } from '@vuex-orm/core'
import Game from '@/models/Game'
import Partner from '@/models/Partner'

export default class GamePartner extends Model {
  static entity = 'gamePartner'

  static primaryKey = ['gamePartnerUid', 'gameId']

  static fields() {
    return {
      gameId: this.number(0),
      gamePartnerUid: this.attr(null),
      isActive: this.boolean(false),
      assigned: this.boolean(false),
      gameSettings: this.attr('', (settings) =>
        settings && typeof settings === 'string'
          ? JSON.parse(settings)
          : settings
      ),
      game: this.belongsTo(Game, 'id', 'gameId'),
      partner: this.belongsTo(Partner, 'partnerUid', 'gamePartnerUid'),
    }
  }
}
