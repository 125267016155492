import { Snackbar } from 'buefy/dist/components/snackbar'
import _ from 'lodash'

export default {
  methods: {
    showValidationErrors: (errors) =>
      _.toArray(errors).forEach((e) =>
        Snackbar.open({
          message: Array.isArray(e) ? e.pop() : e,
          type: 'is-danger',
          queue: false,
          duration: 5000,
        })
      ),
  },
}
