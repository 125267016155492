import GameDelete from '@/components/Games/GameDelete'
import GameForm from '@/components/Games/GameForm'
import PartnerGameForm from '@/components/Games/PartnerGameFrom'
import PartnerToken from '@/components/Partners/PartnerToken'
import Router from 'vue-router'
import BankForm from './components/Banks/BankForm.vue'
import PartnerForm from './components/Partners/PartnerForm'
import PartnerGames from './components/Partners/PartnerGames'
import PartnerGamesSettings from './components/Partners/PartnerGamesSettings'
import DeleteUser from './components/Users/DeleteUser.vue'
import RolePicker from './components/Users/RolePicker/RolePicker'
import UserForm from './components/Users/UserForm.vue'
import authUser from './models/AuthUser'

let router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "auth" */ './components/Auth'),
    },
    {
      path: '/',
      name: 'home',
      component: () =>
        import(/* webpackChunkName: "home" */ './components/Home'),
      props: true,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'users',
          name: 'users',
          props: true,
          component: () =>
            import(/* webpackChunkName: "users" */ './components/Users/Users'),
          children: [
            {
              path: 'userForm',
              name: 'userForm',
              props: true,
              component: UserForm,
              children: [
                {
                  path: 'editRoles',
                  name: 'editRoles',
                  props: true,
                  component: RolePicker,
                },
              ],
            },
            {
              path: 'deleteUser',
              name: 'deleteUser',
              props: true,
              component: DeleteUser,
            },
            {
              path: 'restoreUser',
              name: 'restoreUser',
              props: true,
              component: DeleteUser,
            },
          ],
        },
        {
          path: 'games',
          name: 'games',
          props: true,
          component: () =>
            import(/* webpackChunkName: "games" */ './components/Games/Games'),
          children: [
            {
              path: 'form',
              name: 'partnerGameForm',
              props: true,
              component: PartnerGameForm,
            },
          ],
        },
        {
          path: 'allgames',
          name: 'allgames',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "allgames" */ './components/Games/AllGames'
            ),
          children: [
            {
              path: 'form',
              name: 'gameForm',
              props: true,
              component: GameForm,
            },
            {
              path: 'gameDelete',
              name: 'gameDelete',
              props: true,
              component: GameDelete,
            },
          ],
        },
        {
          path: 'other',
          name: 'other',
          props: true,
          component: () =>
            import(/* webpackChunkName: "other" */ './components/Other/Other'),
        },
        {
          path: 'partners',
          name: 'partners',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "partners" */ './components/Partners/Partners'
            ),
          children: [
            {
              path: 'newPartner',
              name: 'newPartner',
              props: true,
              component: PartnerForm,
            },
            {
              path: 'partnerGames',
              name: 'partnerGames',
              props: true,
              component: PartnerGames,
            },
            {
              path: 'partnerGamesSettings',
              name: 'partnerGamesSettings',
              props: true,
              component: PartnerGamesSettings,
            },
            {
              path: 'token',
              name: 'partnerToken',
              props: true,
              component: PartnerToken,
            },
          ],
        },
        {
          path: 'banks',
          name: 'banks',
          props: true,
          component: () =>
            import(/* webpackChunkName: "banks" */ './components/Banks/Banks'),
          children: [
            {
              path: 'newBank',
              name: 'newBank',
              props: true,
              component: BankForm,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    authUser
      .dispatch('checkExpiresIn') // проверка токена на время действия
      .then(
        () => next(),
        () => next({ path: '/login', params: { nextUrl: to.fullPath } })
      )
  } else {
    next()
  }
})

export default router
