import dateParser from '@/helpers/dateParser'
import Role from '@/models/Role'
import RoleUser from '@/models/RoleUser'
import { Model } from '@vuex-orm/core'
import Partner from '@/models/Partner'

export default class User extends Model {
  static entity = 'users'

  static fields() {
    return {
      id: this.increment(),
      username: this.attr(''),
      email: this.attr(''),
      partnerUid: this.attr(''),
      isActive: this.boolean(false),
      deletedAt: this.attr('', (date) => dateParser.beautifyDate(date)),
      createdAt: this.attr('', (date) => dateParser.beautifyDate(date)),
      updatedAt: this.attr('', (date) => dateParser.beautifyDate(date)),
      roles: this.belongsToMany(Role, RoleUser, 'userId', 'roleId'),
      roleUsers: this.hasMany(RoleUser, 'userId'),
      partner: this.hasOne(Partner, 'partnerUid', 'partnerUid'),
    }
  }
}
