<template>
  <b-field
    :message="errors.first(title)"
    :type="{ 'is-danger': errors.has(title) }"
  >
    <p class="control">
      <span class="button is-static">{{ title }}</span>
    </p>
    <b-dropdown
      v-model="selectedValue"
      v-validate="{ required: true }"
      :name="title"
    >
      <button slot="trigger" class="button">
        <span v-if="!selectedValue">Select</span>
        <span v-else>{{ selectedValue }}</span>
        <b-icon icon="menu-down"></b-icon>
      </button>
      <b-dropdown-item
        v-for="(number, index) in value"
        :key="number + index"
        :value="number"
      >
        {{ number }}
      </b-dropdown-item>
    </b-dropdown>
  </b-field>
</template>

<script>
export default {
  name: 'OptedPositiveIntegerParameter',

  inject: ['$validator'],

  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    stored: {
      type: Number,
      default: undefined,
    },
    editable: {
      type: Boolean,
    },
  },

  data() {
    return {
      selectedValue: this.stored,
    }
  },

  watch: {
    selectedValue() {
      this.$emit('update:stored', this.selectedValue)
    },
  },

  methods: {
    parseInteger() {
      this.selectedValue = parseInt(this.selectedValue)
    },
  },
}
</script>
